import React from "react";
import { Modal, Tab, Button, List } from "semantic-ui-react";
import {
    IMainInfoProps,
    IRestrictionProps,
    IDataProvidersProps,
    IStreamingProps
} from "../interfaces/creation-form";
import {
    putGroups,
    createGroup,
    deleteGroup,
    fetchGroups
} from '../../../../redux/routines';
import { connect } from 'react-redux';
import { Country } from "../../../../common/types/country";
import { State } from "../../../../common/types/state";
import {Group} from "../../../../common/types/group";
import { toBase64 } from "../../../../utils/helpers";
import imageCompression from 'browser-image-compression';
import './styles.scss'
import MainInfoTab from "./children/main-info-tab/main-info.tab";
import RestrictionsTab from "./children/restrictions-tab/restrictions-tab";
import DataProvidersTab from "./children/data-providers-tab/data-providers-tab";
import moment from 'moment';
import validateGroup from "../../../../utils/validateGroup";
import { actions as toastrActions } from 'react-redux-toastr'
import { bindActionCreators } from 'redux';
import client from "../../../../utils/client";
import env from "../../../../env";
import {User} from "../../../../common/types/user";
import StreamingTab from "./children/streaming-tab/streaming-tab";

export interface IGroupModalProps {
    createGroup: (group) => void;
    clearParentState: () => void;
    deleteGroup: (x: { groupId: string, reason: string, hardDelete: boolean }) => void;
    putGroups: (group) => void;
    fetchGroups: () => void;
    sports: string[];
    competitions: string[]
    restrictions: IRestrictionProps,
    mainInfo: IMainInfoProps,
    countries: Country[]
    groups: {
        source: Group[] | Group;
        isLoading: boolean,
        exception: string
    }
    isCreationModal: boolean,
    id: number,
    isOpened: boolean;
    toastr: any;
    currentUser: User;
    itemToUpdate: any;
    tabIndex: number;
    setTabIndex: (index: number) => void;
}

export interface IGroupManagementModalState {
    formPage: number,
    mainInfo: IMainInfoProps,
    restrictions: IRestrictionProps,
    groups: Group[],
    focusedInput: any
    picture: string | null;
    modalProps: any;
    loading: boolean;
}

class GroupModal extends React.Component<IGroupModalProps, IGroupManagementModalState> {
    constructor(props: any) {
        super(props);

        this.state = {
            formPage: 0,
            mainInfo: this.props.mainInfo,
            restrictions: this.props.restrictions,
            groups: [],
            focusedInput: null,
            picture: this.props.mainInfo.picture,
            modalProps: null,
            loading: true
        };
    }

    tabs = ['Main Info', 'Restrictions', 'Streaming', 'Contract Specs', 'Content Providers'];

    groupMapper = (dc) => {
        const isString = typeof (dc) === 'string';
        return isString ? this.props.countries.find(x => x.alpha2Code === dc.toUpperCase()) : dc;
    }

    createGroup = (model) => {
        const mappedCountries = model.restriction.disabledCountries === undefined ? [] : model.restriction.disabledCountries.map(this.groupMapper);
        model.restriction.disabledCountries = mappedCountries;
        var result = this.props.createGroup(model);
    };

    updateGroup = (model) => {
        this.props.putGroups(model);
    }

    async onDrop(files: File[]) {
        const options = {
            maxSizeMB: .25,
            maxWidthOrHeight: 512,
            useWebWorker: true
        }
        const file = files[files.length - 1];
        const compressedFile = await imageCompression(file, options)
        const base64 = await toBase64(compressedFile);
        this.setState({
            picture: base64 as string
        })
    }

    setMainInfoState(values: IMainInfoProps) {
        const restrictions = { ...this.state.restrictions };
        restrictions.isTrial = values.isTrial;
        restrictions.trial = values.trial;
        this.setState({ mainInfo: values });
        this.setState({ restrictions: restrictions });
    }

    setRestrictionsState(restrictions: IRestrictionProps) {
        this.setState({ restrictions: restrictions });
    }

    setStreamingState(values: IStreamingProps) {
        const restrictions = { ...this.state.restrictions };
        restrictions.isHdPlayerEnabled = values.isHdPlayerEnabled;
        restrictions.isHdPlayerPaywallEnabled = values.isHdPlayerPaywallEnabled;
        restrictions.isSdPlayerEnabled = values.isSdPlayerEnabled;
        restrictions.isSdPlayerPaywallEnabled = values.isSdPlayerPaywallEnabled;
        restrictions.isHdHlsEnabled = values.isHdHlsEnabled;
        restrictions.isHdHlsPaywallEnabled = values.isHdHlsPaywallEnabled;
        restrictions.isSdHlsEnabled = values.isSdHlsEnabled;
        restrictions.isSdHlsPaywallEnabled = values.isSdHlsPaywallEnabled;
        restrictions.isHdVodEnabled = values.isHdVodEnabled;
        restrictions.isHdVodPaywallEnabled = values.isHdVodPaywallEnabled;
        restrictions.isSdVodEnabled = values.isSdVodEnabled;
        restrictions.isSdVodPaywallEnabled = values.isSdVodPaywallEnabled;
        restrictions.latencyToleranceMs = values.latencyToleranceMs;
        restrictions.bufferingMs = values.bufferingMs;
        this.setState({ restrictions: restrictions });
    }

    setDataProvidersState(values: IDataProvidersProps) {
        const restrictions = { ...this.state.restrictions };
        restrictions.isSPRAVEnabled = values.isSPRAVEnabled;
        restrictions.isStatsPerformContentEnabled = values.isStatsPerformContentEnabled;
        restrictions.isInPlayContentEnabled = values.isInPlayContentEnabled;
        restrictions.isXVIContentEnabled = values.isXVIContentEnabled;
        restrictions.isModusContentEnabled = values.isModusContentEnabled;
        restrictions.isInternalScheduleContentEnabled = values.isInternalScheduleContentEnabled;
        restrictions.isSpringMediaContentEnabled = values.isSpringMediaContentEnabled;
        restrictions.isInfrontContentEnabled = values.isInfrontContentEnabled;
        restrictions.isCSMContentEnabled = values.isCSMContentEnabled;
        restrictions.isTDIContentEnabled = values.isTDIContentEnabled;
        restrictions.isTrintaContentEnabled = values.isTrintaContentEnabled;
        restrictions.isBOXXERContentEnabled = values.isBOXXERContentEnabled;

        restrictions.isStatsPerformPaywallEnabled = values.isStatsPerformPaywallEnabled;
        restrictions.isSPRAVPaywallEnabled = values.isSPRAVPaywallEnabled;
        restrictions.isInPlayPaywallEnabled = values.isInPlayPaywallEnabled;
        restrictions.isXVIPaywallEnabled = values.isXVIPaywallEnabled;
        restrictions.isModusPaywallEnabled = values.isModusPaywallEnabled;
        restrictions.isInternalSchedulePaywallEnabled = values.isInternalSchedulePaywallEnabled;
        restrictions.isSpringMediaPaywallEnabled = values.isSpringMediaPaywallEnabled;
        restrictions.isInfrontPaywallEnabled = values.isInfrontPaywallEnabled;
        restrictions.isCSMPaywallEnabled = values.isCSMPaywallEnabled;
        restrictions.isTDIPaywallEnabled = values.isTDIPaywallEnabled;
        restrictions.isTrintaPaywallEnabled = values.isTrintaPaywallEnabled;
        restrictions.isBOXXERPaywallEnabled = values.isBOXXERPaywallEnabled;

        this.setState({ restrictions: restrictions });
    }

    confirmForm() {
        const model = {
            id: this.props.id,
            contact: this.state.mainInfo.contact,
            email: this.state.mainInfo.email,
            clientAdminEmail: this.state.mainInfo.clientAdminEmail,
            addresses: this.state.mainInfo.addresses,
            name: this.state.mainInfo.name,
            picture: this.state.picture,
            disabled: this.state.mainInfo.disabled,
            licensesCount: this.state.mainInfo.numberOfLicences,
            restriction: { ...this.state.restrictions },
        }

        const groupToUpdateName = (this.props.itemToUpdate && this.props.itemToUpdate!.name) || '';
        const errors = validateGroup(model, this.props.isCreationModal, this.tabs[this.props.tabIndex], this.props.groups.source, groupToUpdateName);
        if (Object.keys(errors).length > 0) {
            this.props.toastr.add({
                type: 'error',
                title: 'The following fields are required: ',
                position: 'top-center',
                message: '',
                timeOut: 6000,
                options: {
                    component: <List bulleted>{Object.values(errors).map(x => <List.Item>{x}</List.Item>)}</List>,
                    showCloseButton: true, closeOnToastrClick: true,
                }
            });
            return;
        } else {
            if (this.props.tabIndex + 1 !== this.tabs.length - 1) {
                this.props.setTabIndex(this.props.tabIndex + 1)
            } else {
                if (!this.props.isCreationModal) {
                    this.updateGroup(model);
                    return;
                }
                this.createGroup(model);
                this.handleClose();
            }
        }
    }

    moveBack = () => {
        this.props.setTabIndex(this.props.tabIndex - 1);
    }

    async getSingleGroup() {
        this.setState({ loading: true });
        var groupId = this.props.id;
        if (groupId === 0) {
            this.setState({ loading: false });
            return;
        }
        document.body.style.cursor = 'wait';
        try {
            var url = env.apiUrl + '/api/groups/' + groupId;
            var response = await client.get(url);
            var g = response.data as any;
            this.setMainInfoState({
                addresses: g.addresses,
                contact: g.contact,
                disabled: g.disabled,
                email: g.email,
                name: g.name,
                numberOfLicences: g.licensesCount,
                picture: g.picture,
                isTrial: g.restriction.isTrial,
                trial: g.restriction.trial,
                clientAdminEmail: g.clientAdminEmail,
            });
            this.setState({ picture: g.picture });
            this.setRestrictionsState(g.restriction);
        }
        catch (e) {
            console.error('BOOM', e);
        }
        finally {
            document.body.style.cursor = 'default';
            this.setState({ loading: false });
        }

        this.setState({});
    }
    async componentDidMount() {
        await this.getSingleGroup();
        if (this.props.isCreationModal) this.resetForm();
    }

    componentWillUnmount() {
        this.props.setTabIndex(0);
    }

    changeFocusedInput(focusedInput) {
        this.setState({ focusedInput })
    }

    resetForm() {
        this.setMainInfoState({
            addresses: [],
            contact: '',
            disabled: false,
            email: '',
            name: '',
            numberOfLicences: 20,
            picture: '',
            isTrial: false,
            trial: {
                startTime: moment(),
                endTime: moment()
            },
            clientAdminEmail: ''
        })
        this.setRestrictionsState({
            isHdPlayerEnabled: false,
            isHdPlayerPaywallEnabled: false,
            isSdPlayerEnabled: false,
            isSdPlayerPaywallEnabled: false,
            isHdHlsEnabled: false,
            isHdHlsPaywallEnabled: false,
            isSdHlsEnabled: false,
            isSdHlsPaywallEnabled: false,
            isHdVodEnabled: false,
            isHdVodPaywallEnabled: false,
            isSdVodEnabled: false,
            isSdVodPaywallEnabled: false,
            isTrial: false,
            isMaxPlayersEnabled: false,
            isRtmpEnabled: false,
            isDocumentsDownloadEnabled: false,
            isStreamsDownloadEnabled: false,
            isSPRAVEnabled: false,
            isRunningBallEnabled: false,
            isStatsPerformContentEnabled: false,
            isInPlayContentEnabled: false,
            isXVIContentEnabled: false,
            isModusContentEnabled: false,
            isInternalScheduleContentEnabled: false,
            isSpringMediaContentEnabled: false,
            isInfrontContentEnabled: false,
            isCSMContentEnabled: false,
            isTDIContentEnabled: false,
            isTrintaContentEnabled: false,
            isBOXXERContentEnabled: false,
            isStatsPerformPaywallEnabled: false,
            isSPRAVPaywallEnabled: false,
            isInPlayPaywallEnabled: false,
            isXVIPaywallEnabled: false,
            isModusPaywallEnabled: false,
            isInternalSchedulePaywallEnabled: false,
            isSpringMediaPaywallEnabled: false,
            isInfrontPaywallEnabled: false,
            isCSMPaywallEnabled: false,
            isTDIPaywallEnabled: false,
            isTrintaPaywallEnabled: false,
            isBOXXERPaywallEnabled: false,
            is24HrEnabled: false,
            isCustomScheduleEnabled: false,
            isScheduleApiStreamDownloadEnabled: false,
            isVodClippingEnabled: false,
            disabledSports: [],
            disabledCompetitions: [],
            trial: {
                startTime: moment(),
                endTime: moment().add('14', 'd'),
            },
            isDomainOnlyEnabled: false,
            domainsWithAccess: "",
            isRequiredSport: false,
            isRequiredCompetition: false,
            isSkyBoxesAllowed: false,
            isInternalStreamsAllowed: false,
            devicesAllowedEnabled: false,
            devicesCount: 0,
            latencyToleranceMs: 1250,
            bufferingMs: 1250
        })
    }

    handleClose = () => {
        this.props.setTabIndex(0);
        this.props.clearParentState();

        this.resetForm();
    };

    renderButtons = (panes) => (
        <>
        {
            this.props.tabIndex !== 0 &&
            <Button type='button' onClick={this.moveBack.bind(this)} color="grey">
                Back
            </Button>
        }
        {
        this.props.tabIndex !== panes.length - 1 ?
            <Button type='button' onClick={this.confirmForm.bind(this)} color="green">
                Next
            </Button> :
            <Button type='button' disabled={this.props.groups.isLoading} onClick={this.confirmForm.bind(this)} color="green">
                {this.props.isCreationModal ? "Create" : "Update"}
            </Button>
        }
        </>
    )

    render() {
        if (this.state.loading && this.props.id !== 0) return <div>loading...</div>

        const sportsList = this.props.sports || [];
        const competitionsList = this.props.competitions || [];
        const countriesList = this.props.countries || [];

        const countryLabel = (({ label, image }: any) => (
            <div style={{ display: 'flex' }}>
                <img className='country-flag' src={image} alt={label}></img>
                <div>{label}</div>
            </div>
        ));
        const {
            isSPRAVEnabled,
            isStatsPerformContentEnabled,
            isInPlayContentEnabled,
            isXVIContentEnabled,
            isModusContentEnabled,
            isInternalScheduleContentEnabled,
            isSpringMediaContentEnabled,
            isInfrontContentEnabled,
            isCSMContentEnabled,
            isTDIContentEnabled,
            isTrintaContentEnabled,
            isBOXXERContentEnabled,
            isStatsPerformPaywallEnabled,
            isSPRAVPaywallEnabled,
            isInPlayPaywallEnabled,
            isXVIPaywallEnabled,
            isModusPaywallEnabled,
            isInternalSchedulePaywallEnabled,
            isSpringMediaPaywallEnabled,
            isInfrontPaywallEnabled,
            isCSMPaywallEnabled,
            isTDIPaywallEnabled,
            isTrintaPaywallEnabled,
            isBOXXERPaywallEnabled,
            isHdPlayerEnabled,
            isHdPlayerPaywallEnabled,
            isSdPlayerEnabled,
            isSdPlayerPaywallEnabled,
            isHdHlsEnabled,
            isHdHlsPaywallEnabled,
            isSdHlsEnabled,
            isSdHlsPaywallEnabled,
            isHdVodEnabled,
            isHdVodPaywallEnabled,
            isSdVodEnabled,
            isSdVodPaywallEnabled,
            latencyToleranceMs,
            bufferingMs
        } = this.state.restrictions;
        const { isCreationModal } = this.props;
        const panes = [
            {
                menuItem: 'Main Info',
                render: () => <MainInfoTab
                    focusedInput={this.state.focusedInput}
                    changeFocusedInput={this.changeFocusedInput.bind(this)}
                    mainInfo={this.state.mainInfo}
                    onImageDrop={this.onDrop.bind(this)}
                    picture={this.state.picture}
                    setState={this.setMainInfoState.bind(this)}
                    restrictions={this.state.restrictions}
                />,
            },
            {
                menuItem: 'Restrictions',
                render: () => <RestrictionsTab
                    countriesList={countriesList}
                    sportsList={sportsList}
                    competitionsList={competitionsList}
                    countryLabel={countryLabel}
                    restrictions={this.state.restrictions}
                    setState={this.setRestrictionsState.bind(this)}
                    isCreationModal={this.props.isCreationModal}
                />
            },
            {
                menuItem: 'Streaming',
                render: () => <StreamingTab
                    setState={this.setStreamingState.bind(this)}
                    values={{
                        isHdPlayerEnabled,
                        isHdPlayerPaywallEnabled,
                        isSdPlayerEnabled,
                        isSdPlayerPaywallEnabled,
                        isHdHlsEnabled,
                        isHdHlsPaywallEnabled,
                        isSdHlsEnabled,
                        isSdHlsPaywallEnabled,
                        isHdVodEnabled,
                        isHdVodPaywallEnabled,
                        isSdVodEnabled,
                        isSdVodPaywallEnabled,
                        latencyToleranceMs,
                        bufferingMs
                    }}
                />
            },
            {
                menuItem: 'Content Providers',
                render: () => <DataProvidersTab
                    values={{
                        isSPRAVEnabled,
                        isStatsPerformContentEnabled,
                        isInPlayContentEnabled,
                        isXVIContentEnabled,
                        isModusContentEnabled,
                        isInternalScheduleContentEnabled,
                        isSpringMediaContentEnabled,
                        isInfrontContentEnabled,
                        isCSMContentEnabled,
                        isTDIContentEnabled,
                        isTrintaContentEnabled,
                        isBOXXERContentEnabled,
                        isStatsPerformPaywallEnabled,
                        isSPRAVPaywallEnabled,
                        isInPlayPaywallEnabled,
                        isXVIPaywallEnabled,
                        isModusPaywallEnabled,
                        isInternalSchedulePaywallEnabled,
                        isSpringMediaPaywallEnabled,
                        isInfrontPaywallEnabled,
                        isCSMPaywallEnabled,
                        isTDIPaywallEnabled,
                        isTrintaPaywallEnabled,
                        isBOXXERPaywallEnabled
                    }}
                    setState={this.setDataProvidersState.bind(this)}
                />
            },
        ];

        const { isOpened, groups } = this.props;

        return (
            <Modal
                open={isOpened}
                closeOnDimmerClick={!groups.isLoading}
                onClose={() => {
                    this.props.clearParentState();
                }}>
                <Modal.Header>{this.props.isCreationModal ? "Create new group" : "Update group"}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Tab panes={panes} activeIndex={this.props.tabIndex}/>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions style={{ margin: 0 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="group-modal__buttons">
                        {
                           this.renderButtons(panes)
                        }
                        <Button type='button' disabled={groups.isLoading} onClick={this.handleClose} className="ui red basic button cancel-button">Cancel</Button>
                    </div>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state: State) => {
    return {
        groups: state.groups,
        currentUser: state.user,
        countries: state.countries,
        sports: state.sports,
        competitions: state.competitions,
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({ createGroup, deleteGroup, putGroups, fetchGroups }, dispatch),
    toastr: bindActionCreators(toastrActions as any, dispatch)
})


export default connect(mapStateToProps, mapDispatchToProps)(GroupModal);
